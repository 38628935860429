import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { KTSVG } from '../../../_metronic/helpers'
import { ErrorsLayout } from '../errors/ErrorsLayout'

import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import {
  createInquiryNew,
  editInquiryNew,
  getAllOffer,
  getAllProduct,
  getAllUsers,
  getBuyerSeller,
  getCGST,
  getFirmListApi,
  getIGST,
  getInquiryByIdNew,
  getInquiryStatusList,
  getLocation,
  getSGST,
  getTransportListApi,
  uploadInvoice,
} from './Inquiry-list/core/_requests'
import { editOfferNew } from '../offer-management/offers-list/core/_requests'
import { toast, Toaster } from 'react-hot-toast'
import moment from 'moment'
import { checkDecimalNumberValidation } from '../../utils/utils'

// const InquiryStatusList = [
//   {value: '', label: 'Select Inquiry Status'},
//   {value: '1', label: 'Pending'},
//   {value: '2', label: 'Negotiation'},
//   {value: '3', label: 'Quotation Sent'},
//   {value: '4', label: 'Quotaiton Confirmed'},
//   {value: '5', label: 'Vehicle Assigned'},
//   {value: '6', label: 'Invoice Generated'},
//   {value: '7', label: 'Payment Received'},
//   {value: '8', label: 'Transaction Completed'},
//   {value: '9', label: 'Canceled'},
// ]

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const loginSchema = Yup.object().shape({
  // firm_id: Yup.string().required('This Field is required'),
  buyer_person_id: Yup.string().required('This Field is required'),
  buyer_seller_id: Yup.string().required('This Field is required'),
  buyer_whatsapp_number: Yup.string()
    .required('This Field is required')
    .max(12, 'must be less than 12 characters')
    .min(10, 'must be at least 10 characters')
    .matches(phoneRegExp, 'Whatsapp number is not valid'),
  // mobile_number: Yup.string().when('inquiry_status', {
  //   is: (inquiry_status: string) => inquiry_status === '5',
  //   then: Yup.string()
  //     .max(12, 'must be less than 12 characters')
  //     .min(10, 'must be at least 10 characters')
  //     .matches(phoneRegExp, 'Whatsapp number is not valid'),
  // }),

  mobile_number: Yup.string()
    .max(12, 'must be less than 12 characters')
    .min(10, 'must be at least 10 characters')
    .matches(phoneRegExp, 'Whatsapp number is not valid')
    .when('inquiry_status', {
      is: (inquiry_status: any) => inquiry_status == 5,
      then: Yup.string().required('This Field is required'),
    }),
  destination_location_id: Yup.string().required('This Field is required'),
  billing_location_id: Yup.string().required('This Field is required'),
  inquiry_status: Yup.string().required('This Field is required'),
  assigned_to: Yup.string().required('This Field is required'),
  transport_id: Yup.string().when('inquiry_status', {
    is: (inquiry_status: any) => inquiry_status == 5,
    then: Yup.string().required('This Field is required'),
  }),
  driver_name: Yup.string().when('inquiry_status', {
    is: (inquiry_status: any) => inquiry_status == 5,
    then: Yup.string().required('This Field is required'),
  }),
  vehicle_number: Yup.string().when('inquiry_status', {
    is: (inquiry_status: any) => inquiry_status == 5,
    then: Yup.string().required('This Field is required'),
  }),
  // expected_arrival_date: Yup.string().required('This Field is required'),

  // driver_name: Yup.string().when('inquiry_status', {
  //   is: (inquiry_status: string) => inquiry_status === '5',
  //   then: Yup.string().required('This Field is required'),
  // }),
  // product_id:Yup.string().required("This Field is required")
})

const initialValues = {
  buyer_seller_id: '',
  buyer_person_id: '',
  mobile_number: '',
  destination_location_id: '',
  billing_location_id: '',
  buyer_billing_address_id: '',
  buyer_shipping_address_id: '',
  inquiry_status: '',
  assigned_to: '',
  product_id: '',
  driver_name: '',
  vehicle_number: '',
  transport_id: '',
  buyer_whatsapp_number: '',
  invoice_file: '',
  eway_bill_file: '',
  expected_arrival_date: '',
}

const AddEditInquiry = () => {
  const navigate = useNavigate()
  const params = useParams()
  const id: string = params.id ? params.id : ''

  const [loadingList, setLoadingList] = useState(false)
  const [loadingGetData, setLoadingGetData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lstProductDetails, setLstProductDetails] = useState([
    {
      product_quantity: '',
      rate: '',
      product_id: '',
      offer_id: ''
    },
  ])
  const [lstFirm, setLstFirm] = useState([])
  const [lstFirmMaster, setLstFirmMaster] = useState([])
  const [personList, setPersonList] = useState<any>([])
  const [selectedMasterFirm, setSelectedMasterFirm] = useState<any>({})
  const [lstBuyyerSeller, setLstBuyyerSeller] = useState([])
  const [billingAddressList, setBillingAddressList] = useState([])
  const [shippingAddressList, setShippingAddressList] = useState([])
  const [lstLocation, setLstLocation] = useState([])
  const [lstAssigned, setLstAssigned] = useState([])
  const [lstMasterProduct, setLstMasterProduct] = useState([])
  const [lstProduct, setLstProduct] = useState([])
  const [lstOffer, setLstOffer] = useState([])
  const [InquiryStatusList, setInquiryStatusList] = useState([])
  const [oldDataById, setOldDataById] = useState<any>({})
  const [transporterList, setTransporterList] = useState([])

  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })
  const [valueSGST, setValueSGST] = useState(0)
  const [valueIGST, setValueIGST] = useState(0)
  const [valueCGST, setValueCGST] = useState(0)
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  useEffect(() => {
    getList()
  }, [])

  const getDataOfInquiryStatusList = async () => {
    try{
      //Inquiry List
      let dataInquiryList: any = await getInquiryStatusList()
      let lstTmpInquiryList = dataInquiryList?.responseData?.list.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setInquiryStatusList(lstTmpInquiryList)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfFirmList = async () => {
    try{
      // Firm
      let dataFirm: any = await getFirmListApi()

      let lstTmpFirm = dataFirm.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.firm_name }
      })
      setLstFirmMaster(dataFirm.responseData)
      setLstFirm(lstTmpFirm)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfTransportList = async () => {
    try{
      // Transporter List
      let dataTransport: any = await getTransportListApi()
      let lstTmpTransport = dataTransport.data.map((obj: any) => {
        return { value: obj.id, label: obj.firm_name }
      })
      setTransporterList(lstTmpTransport)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfBuyerSellerList = async () => {
    try{
      // Buyer Seller
      let dataBuyerSeller: any = await getBuyerSeller()
      let lstTmpBuyerSeller = dataBuyerSeller.data.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setLstBuyyerSeller(lstTmpBuyerSeller)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfLocationList = async () => {
    try{
      // Location
      let dataLocation: any = await getLocation()
      let lstTmpLocation = dataLocation.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setLstLocation(lstTmpLocation)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfUserList = async () => {
    try{
      // Assigned
      let dataUser: any = await getAllUsers()
      let lstTmpUser = dataUser.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setLstAssigned(lstTmpUser)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfProductList = async () => {
    try{
      // Product
      let dataProduct: any = await getAllProduct()
      let lstTmpProduct = dataProduct.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.product_variation }
      })
      setLstMasterProduct(dataProduct.responseData)
      setLstProduct(lstTmpProduct)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfGST = async () => {
    try{
      // SGST
      let SGST: any = await getSGST()

      let tmpSGST = SGST.responseData.value
      setValueSGST(tmpSGST)

      // IGST
      let IGST: any = await getIGST()
      let tmpIGST = IGST.responseData.value
      setValueIGST(tmpIGST)

      // CGST
      let CGST: any = await getCGST()
      let tmpCGST = CGST.responseData.value
      setValueCGST(tmpCGST)
    }
    catch(err){
      console.log(err)
    }
  }

  const getDataOfOfferList = async () => {
    try{
      let dataOffer: any = await getAllOffer()
      let lstTmpOffer = dataOffer.responseData.map((obj: any) => {
        console.log(obj, 'obj===')

        return { ...obj, value: obj.id, label: obj.offer_number }
      })
      setLstOffer(lstTmpOffer)
    }
    catch(err){
      console.log(err)
    }
  }

  const getList = async () => {
    try {
      setLoadingList(true)
      await Promise.all([
        getDataOfInquiryStatusList(),
        getDataOfFirmList(),
        getDataOfTransportList(),
        getDataOfBuyerSellerList(),
        getDataOfLocationList(),
        getDataOfUserList(),
        getDataOfProductList(),
        getDataOfGST(),
        getDataOfOfferList()
      ])
      setLoadingList(false)
    }
    catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (id) {
      getByID(id)
    }
  }, [id, lstFirmMaster])

  const selectedBuyerDetails = (objTmp: any, type: any, oldData: any = {}) => {
    setSelectedMasterFirm(objTmp)
    let personData = objTmp?.firmPersonInfo.map((x: any, i: any) => {
      return {
        label: x?.name ?? "",
        value: x?.id ?? "",
        whatsapp_number: x?.whatsapp_number ?? ""
      }
    }) ?? []

    if (type == "edit" && oldData?.firmBuyerInfo) {
      if (!personData.find((x: any) => x.value == oldData?.firmBuyerInfo?.id)) {
        personData.push({ label: oldData?.firmBuyerInfo?.name ?? "", value: oldData?.firmBuyerInfo?.id ?? "", whatsapp_number: oldData?.firmBuyerInfo?.whatsapp_number ?? "" })
      }
    }
    else if (type == "add" && oldDataById?.firmBuyerInfo && id) {
      if (objTmp?.id == oldDataById?.buyerSellerInfo?.id && !personData.find((x: any) => x.value == oldDataById?.firmBuyerInfo?.id)) {
        personData.push({ label: oldDataById?.firmBuyerInfo?.name ?? "", value: oldDataById?.firmBuyerInfo?.id ?? "", whatsapp_number: oldDataById?.firmBuyerInfo?.whatsapp_number ?? "" })
      }
    }

    let buyerSellerBillingAddress = objTmp?.buyerSellerPrimaryBillingInfo?.map((x: any) => {
      return {
        label: x?.city ?? "",
        value: x?.id ?? "",
        district: x?.addressLocationInfo?.name ?? "",
        id: x?.addressLocationInfo?.id ?? "",
      }
    }) ?? []

    let buyerSellerShippingAddress = objTmp?.buyerSellerPrimaryShippingInfo?.map((x: any) => {
      return {
        label: x?.city ?? "",
        value: x?.id ?? "",
        district: x?.addressLocationInfo?.name ?? "",
        id: x?.addressLocationInfo?.id ?? "",
      }
    }) ?? []

    if (type == "edit" && oldData?.destinationLocationInfo) {

      if (!buyerSellerShippingAddress.find((x: any) => x.value == oldData?.destinationLocationInfo?.id && x.value == oldData?.buyer_shipping_address_id)) {
        buyerSellerShippingAddress.push({
          label: oldData?.shippingAddressLocationInfo?.city ?? "",
          value: oldData?.buyer_shipping_address_id, 
          district: oldData?.destinationLocationInfo?.name, 
          id: oldData?.destinationLocationInfo?.id 
        })
      }
    }
    // else if (type == "add" && oldDataById?.destinationLocationInfo && id) {
    //   if (objTmp?.id == oldDataById?.buyerSellerInfo?.id && !buyerSellerShippingAddress.find((x: any) => x.value == oldDataById?.destinationLocationInfo?.id)) {
    //     buyerSellerShippingAddress.push({ label: oldDataById?.destinationLocationInfo?.name, value: oldDataById?.destinationLocationInfo?.id, id: oldDataById?.buyer_shipping_address_id })
    //   }
    // }

    if (type == "edit" && oldData?.billingLocationInfo) {
      if (!buyerSellerBillingAddress.find((x: any) => x.id == oldData?.billingLocationInfo?.id && x.value == oldData?.buyer_billing_address_id)) {
        buyerSellerBillingAddress.push({
          label: oldData?.billingAddressLocationInfo?.city ?? "",
          value: oldData?.buyer_billing_address_id, 
          district: oldData?.billingLocationInfo?.name, 
          id: oldData?.billingLocationInfo?.id 
        })

        // buyerSellerBillingAddress.push({ 
        //   label: oldData?.billingLocationInfo?.name, 
        //   value: oldData?.billingLocationInfo?.id 
        // })
      }
    }
    // else if (type == "add" && oldDataById?.billingLocationInfo && id) {
    //   if (objTmp?.id == oldDataById?.buyerSellerInfo?.id && !buyerSellerBillingAddress.find((x: any) => x.value == oldDataById?.billingLocationInfo?.id)) {
    //     buyerSellerBillingAddress.push({ label: oldDataById?.billingLocationInfo?.name, value: oldDataById?.billingLocationInfo?.id, id: oldDataById?.buyer_billing_address_id })
    //   }
    // }

    setPersonList(personData)
    setBillingAddressList(buyerSellerBillingAddress)
    setShippingAddressList(buyerSellerShippingAddress)
  }

  console.log("loggggggggggggggg11",personList)
  console.log("loggggggggggggggg22",billingAddressList)
  console.log("loggggggggggggggg33",shippingAddressList)

  // Edit
  const getByID = async (id: string) => {
    try {
      setLoadingGetData(true)
      let data: any = await getInquiryByIdNew(id)
      console.log(data.responseData.inquiry_status, 'data.responseData.inquiry_status')
      if (data.responseData) {
        console.log("data?.responseDatadata?.responseData", data?.responseData)
        setOldDataById(data?.responseData ?? {})
        formik.setFieldValue('buyer_seller_id', data?.responseData?.buyer_seller_id ?? '')
        formik.setFieldValue('vehicle_number', data?.responseData?.vehicle_number ?? '')
        selectedBuyerDetails(data?.responseData?.buyerSellerInfo ?? {}, "edit", data?.responseData ?? {})
        formik.setFieldValue('buyer_whatsapp_number', data?.responseData?.firmBuyerInfo?.whatsapp_number ?? '')
        formik.setFieldValue('buyer_person_id', data?.responseData?.buyer_person_id ?? '')
        formik.setFieldValue('mobile_number', data?.responseData?.mobile_number ? data?.responseData?.mobile_number : '')
        formik.setFieldValue('destination_location_id', data?.responseData?.destination_location_id ?? "")
        formik.setFieldValue('billing_location_id', data?.responseData?.billing_location_id ?? "")
        formik.setFieldValue('buyer_billing_address_id', data?.responseData?.buyer_billing_address_id ?? "")
        formik.setFieldValue('buyer_shipping_address_id', data?.responseData?.buyer_shipping_address_id ?? "")
        formik.setFieldValue('inquiry_status', data?.responseData?.inquiry_status ? parseInt(data.responseData.inquiry_status) : "")
        formik.setFieldValue('assigned_to', data?.responseData?.assigned_to ?? "")
        formik.setFieldValue('eway_bill_file', data?.responseData?.eway_bill_file ?? "")
        formik.setFieldValue('invoice_file', data?.responseData?.invoice_file ?? "")
        formik.setFieldValue('whatsapp_number', data?.responseData?.buyerSellerInfo.whatsapp_number ?? "")
        formik.setFieldValue('transport_id', data?.responseData?.transport_id ?? "")
        formik.setFieldValue('driver_name',
          data?.responseData?.driver_name ? data?.responseData?.driver_name : ''
        )
        formik.setFieldValue('expected_arrival_date', data?.responseData?.expected_arrival_date ?? "")
        setLstProductDetails(data?.responseData?.inquiryInfo)
        formik.validateForm()
        setLoadingGetData(false)
      }
    }
    catch (err) {
      setLoadingGetData(false)
      console.error(err)
    }
  }


  // Firm
  const selectedValueFirm = (id: string) => {
    let selected = lstFirm.filter((obj: any) => obj.value == id)
    console.log(selected.length)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  const getMasterFirmData = (id: string) => {
    let selected = lstFirmMaster.filter((obj: any) => {
      if (obj.id == id) return obj
    })
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }
  const selectedValueTransport = (id: string) => {
    let selected = transporterList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Buyer Seller
  const selectedValueBuyer = (id: string) => {
    let selected = personList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Billing Location
  const selectedValueBillingLocation = (id: string) => {
    let selected = billingAddressList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Shipping Location
  const selectedValueShippingLocation = (id: string) => {
    let selected = shippingAddressList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Inquiry status
  const selectedValueInquiry = (id: string) => {
    let selected = InquiryStatusList.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Asigned
  const selectedValueAsigned = (id: string) => {
    let selected = lstAssigned.filter((obj: any) => obj.value == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // Product
  const selectedValueProduct = (id: string) => {
    let selected = lstProduct.filter((obj: any) => obj.value == id)
    if (selected.length) {
      // // @ts-ignore
      // let data  = selected[0].value
      // formik.setFieldValue("product_id",data)
      return selected[0]
    } else {
      return []
    }
  }
  // Get master object
  const getMasterProductObject = (id: string) => {
    let selected = lstMasterProduct.filter((obj: any) => obj.id == id)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  const onChangeProductDetail = (index: number, key: string, newVal: string) => {
    let lstTmp: any = [...lstProductDetails]
    if (key == 'product_id') {
      let objectMain = getMasterProductObject(newVal) as any
      console.log(objectMain, "objectMain")
      lstTmp[index] = { ...lstTmp[index], [key]: newVal, rate: objectMain.price } as any
    } else {
      lstTmp[index] = { ...lstTmp[index], [key]: newVal } as any
    }
    console.log({ ...lstTmp[index], [key]: newVal }, 'lstTmp')

    setLstProductDetails(lstTmp)
  }

  const navigateInuiryListing = () => {
    //   // 👇️ navigate to /
    navigate('/inquiry-management/Inquiries')
  }

  const successToast = (message: string) => toast.success(message)
  const errorToast = (message: string) => toast.error(message)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      // setFieldError()
      try {
        const allTrue = lstProductDetails.every((obj) => {
          if (obj.offer_id != '' && obj.product_id != '' && obj.product_quantity != '' && obj.rate != '') return true
        })
        if (!allTrue) {
          errorToast('Please fill all data in Product Details')
          // call your method
          setSessionMessage({
            type: 'danger',
            message: 'Please fill all data in Product Details',
            show: true,
          })
          return
        }

        let objFinal = { ...values, inquiry_details: lstProductDetails, status: 1 }
        if (id) {
          console.log(objFinal, 'values-------------objFinal')
          let dataNew: any = await editInquiryNew(id, objFinal)
          if (dataNew.responseStatus == 200) {
            setLoading(false)
            // navigate('/inquiry-management/inquiries')
            navigate('/inquiry-management/inquiries', {
              state: { type: 'success', message: dataNew.responseMessage, show: true },
            })
          } else {
            console.log('calllllllllll')

            errorToast('Something went wrong')
            // setSessionMessage({type: 'danger', message: dataNew?.responseMessage, show: true})
            setLoading(false)
          }
        } else {
          const dataNew: any = await createInquiryNew(objFinal)
          if (dataNew.responseStatus == 200) {
            // setSessionMessage({type: 'success', message: dataNew.responseMessage, show: true})
            // navigate('/inquiry-management/inquiries')
            navigate('/inquiry-management/inquiries', {
              state: { type: 'success', message: dataNew.responseMessage, show: true },
            })
            setLoading(false)
          } else {
            errorToast('Something went wrong')
            // setSessionMessage({type: 'danger', message: dataNew.responseMessage, show: true})
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error)
        errorToast('Something went wrong')
        setStatus('something is wrong please try agian')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // console.log(lstProductDetails, 'lstProductDetails')

  useEffect(() => {
    formik.validateForm()
  }, [])

  console.log(formik.errors, 'formik.errors')

  // upload Invoice File
  const uploadInvoiceFile = async (file: any) => {
    var formData = new FormData()
    formData.append(`invoice`, file)
    let filePath: any = await uploadInvoice(formData)
    console.log(filePath.newFilename, 'filePath')
    formik.setFieldValue('invoice_file', filePath.newFilename)
  }

  // upload E Way Bill File
  const uploadEwayBill = async (file: any) => {
    var formData = new FormData()
    formData.append(`invoice`, file)
    let filePath: any = await uploadInvoice(formData)
    console.log(filePath.newFilename, 'filePath')
    formik.setFieldValue('eway_bill_file', filePath.newFilename)
  }

  // upload E Way Bill File
  const getTotal = (objProduct: any) => {
    let tmp: any =
      parseFloat(objProduct.rate) && parseFloat(objProduct.product_quantity)
        ? parseFloat(objProduct.rate) * parseFloat(objProduct.product_quantity) * 1000
        : 0
    let tax = (tmp / 100) * valueIGST
    let finalAmt = tmp
    return finalAmt.toFixed(2)
  }

  const dateForPicker = (dateString: any) => {
    return moment(new Date(dateString)).format('YYYY-MM-DD')
  }

  const setProductWithOffer = (index: any, data: any) => {
    let lstTmp: any = [...lstProductDetails]
    lstTmp[index] = { ...lstTmp[index], 'offer_id': data.value, 'product_id': data.product_id, 'product_quantity': data.quantity, 'rate': data.price } as any
    setLstProductDetails(lstTmp)
  }

  console.log("formikformikformikformik", formik)

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {loadingGetData || loadingList ? (
          <div
            style={{
              height: '550px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation='border' />
          </div>
        ) : (
          <>
            <h2 className='mb-5'> Buyer Details </h2>
            <Row className='mb-5'>
              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'> Firm Name </Form.Label>
                  <Select
                    options={lstFirm}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Firm'
                    value={selectedValueFirm(formik.values.buyer_seller_id)}
                    onChange={(e: any) => {
                      formik.setFieldValue('buyer_whatsapp_number', '')
                      formik.setFieldValue('email', '')
                      formik.setFieldValue('destination_location_id', '')
                      formik.setFieldValue('billing_location_id', '')
                      formik.setFieldValue('buyer_billing_address_id', '')
                      formik.setFieldValue('buyer_shipping_address_id', '')
                      let objTmp: any = getMasterFirmData(e.value)
                      selectedBuyerDetails(objTmp, "add")
                      formik.setFieldValue('buyer_seller_id', e.value)
                    }}
                  />
                  {formik.touched.buyer_seller_id && formik.errors.buyer_seller_id && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.buyer_seller_id}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>

              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'> Buyer Name</Form.Label>
                  <Select
                    options={personList}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select buyer'
                    name={'buyer_person_id'}
                    id={'buyer_person_id'}
                    value={selectedValueBuyer(formik.values.buyer_person_id)}
                    onChange={(e: any) => {
                      formik.setFieldValue('buyer_person_id', e.value)
                      formik.setFieldValue('buyer_whatsapp_number', e.whatsapp_number)
                    }}
                  />
                  {formik.touched.buyer_person_id && formik.errors.buyer_person_id && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.buyer_person_id}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group className='mb-3'>
                  <Form.Label className='fw-bold required form-label'>Whatsapp Number</Form.Label>
                  <Form.Control
                    type={'text'}
                    className=' form-control-solid'
                    placeholder='Whatsapp Number'
                    value={formik.values.buyer_whatsapp_number}
                    disabled={true}
                  />
                  {formik.touched.buyer_whatsapp_number && formik.errors.buyer_whatsapp_number && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.buyer_whatsapp_number}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-5'>
              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'>Shipping City</Form.Label>
                  <Select
                    options={shippingAddressList}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Shipping City'
                    value={selectedValueShippingLocation(formik.values.buyer_shipping_address_id)}
                    onChange={(e: any) => {
                      formik.setFieldValue('destination_location_id', e.id)
                      formik.setFieldValue('buyer_shipping_address_id', e.value)
                    }}
                  />
                  {formik.touched.destination_location_id && formik.errors.destination_location_id && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.destination_location_id}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold form-label'>Expected Date</Form.Label>
                  <Form.Control
                    type='Date'
                    className='form-control-solid'
                    placeholder='Select Date'
                    value={formik.values?.expected_arrival_date ? dateForPicker(formik.values?.expected_arrival_date) : ''}
                    onChange={(e: any) => {
                      formik.setFieldValue('expected_arrival_date', e.target.value)
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'>Billing City</Form.Label>
                  <Select
                    options={billingAddressList}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Billing City'
                    value={selectedValueBillingLocation(formik.values.buyer_billing_address_id)}
                    onChange={(e: any) => {
                      formik.setFieldValue('billing_location_id', e.id)
                      formik.setFieldValue('buyer_billing_address_id', e.value)
                    }}
                  />
                  {formik.touched.billing_location_id && formik.errors.billing_location_id && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.billing_location_id}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <h4 className='mb-5'> Product Details </h4>
            {lstProductDetails.map((objProduct: any, index) => {
              let objectMain = getMasterProductObject(objProduct.product_id) as any
              console.log(objectMain, 'objectMain')
              return (
                <Row className='mb-10 prdct-dtl-grd' key={index}>
                  <Col>
                    <Form.Group>
                      <Form.Label className='fw-bold required form-label'>
                        Select Offer ID
                      </Form.Label>
                      <Select
                        options={lstOffer.filter((x: any) => !lstProductDetails.some((y: any) => x.value === y.offer_id))}
                        className='custom_select'
                        classNamePrefix='Select'
                        placeholder='Offer Id'
                        value={objProduct.offer_id ? lstOffer.find((x: any) => x.value == objProduct.offer_id) : []}
                        onChange={(e: any) => {
                          setProductWithOffer(index, e)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label className='fw-bold required form-label'>
                        Select Product Name
                      </Form.Label>
                      <Select
                        options={lstProduct}
                        className='custom_select'
                        classNamePrefix='Select'
                        placeholder='Product Name'
                        value={selectedValueProduct(objProduct.product_id)}
                        onChange={(e: any) => {
                          onChangeProductDetail(index, 'product_id', e.value)
                          // onChangeProductDetail(index, 'rate', tmp.price)
                          formik.setFieldValue('product_id', e.value)
                        }}
                      />
                      {formik.touched.product_id && formik.errors.product_id && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {formik.errors.product_id}
                          </span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-5'>
                      <Form.Label className='required form-label fw-bold'> Grade Group </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='Grade Group'
                        value={objectMain?.gradeGroupInfo?.name ?? ""}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-5'>
                      <Form.Label className='required form-label fw-bold'>
                        {' '}
                        Inquiry Qty(MT){' '}
                      </Form.Label>
                      <Form.Control
                        type='number'
                        className='form-control-solid'
                        placeholder='Inquiry Quantity'
                        value={objProduct.product_quantity}
                        onChange={(e: any) => {
                          console.log(e.target.value, 'eee')
                          let decimalPattern = /^\d+(\.\d{0,3})?$/;
                          if(decimalPattern.test(e.target.value)){
                            onChangeProductDetail(index, 'product_quantity', e.target.value)
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-5'>
                      <Form.Label className='required form-label fw-bold'>
                        {' '}
                        Inquiry Rate/Kg{' '}
                      </Form.Label>
                      <Form.Control
                        type='number'
                        className='form-control-solid'
                        placeholder='Inquiry Rate '
                        value={objProduct.rate}
                        onChange={(e: any) => {
                          onChangeProductDetail(index, 'rate', e.target.value)
                          // console.log(e.target.value, 'eee')

                          // formik.setFieldValue('price', e.target.value)
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='mb-5'>
                      <Form.Label className='form-label fw-bold'> Amount </Form.Label>
                      <Form.Control
                        type='text'
                        className='form-control-solid'
                        placeholder='Rate'
                        value={getTotal(objProduct) || ''}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <div className='d-flex'>
                      {lstProductDetails.length > 1 && lstProductDetails.length - 1 != index ? (
                        <a
                          onClick={() => {
                            let lstTmp = lstProductDetails.filter((obj, i) => i != index)
                            setLstProductDetails(lstTmp)
                          }}
                          className='btn btn-sm btn-icon mt-8 btn-color-danger'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen036.svg'
                            className='svg-icon-muted svg-icon-2hx'
                          />
                        </a>
                      ) : (
                        <a
                          // href='#'
                          onClick={() => {
                            setLstProductDetails([
                              ...lstProductDetails,
                              {
                                product_quantity: '',
                                rate: '',
                                product_id: '',
                                offer_id: ''
                              },
                            ])
                          }}
                          className='btn btn-sm btn-icon mt-8 btn-color-success'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen035.svg'
                            className='svg-icon-muted svg-icon-2hx me-2'
                          />
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>
              )
            })}
            <Row className='mb-10'>
              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'>Inquiry Status</Form.Label>
                  <Select
                    options={InquiryStatusList}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Inquiry Status'
                    value={selectedValueInquiry(formik.values.inquiry_status)}
                    onChange={(e: any) => {
                      formik.setFieldValue('inquiry_status', e.value)
                    }}
                  />
                  {formik.touched.inquiry_status && formik.errors.inquiry_status && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.inquiry_status}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col xl={4}>
                <Form.Group>
                  <Form.Label className='fw-bold required form-label'> Assigned </Form.Label>
                  <Select
                    options={lstAssigned}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Assigned'
                    value={selectedValueAsigned(formik.values.assigned_to)}
                    onChange={(e: any) => {
                      formik.setFieldValue('assigned_to', e.value)
                    }}
                  />
                  {formik.touched.assigned_to && formik.errors.assigned_to && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.assigned_to}
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {formik.values.inquiry_status === 5 as any && (
              <Row>
                <Col xl={4}>
                  <Form.Label className='required form-label'> Select Transporter </Form.Label>
                  <Select
                    options={transporterList}
                    className='custom_select'
                    classNamePrefix='Select'
                    placeholder='Select Transporter'
                    value={selectedValueTransport(formik.values.transport_id)}
                    onChange={(e: any) => {
                      formik.setFieldValue('transport_id', e.value)
                    }}
                  />
                  {formik.touched.transport_id && formik.errors.transport_id && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.transport_id}
                      </span>
                    </div>
                  )}
                </Col>
                <Col xl={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label className='required form-label'> Driver Name </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-solid'
                      placeholder='Driver Name'
                      value={formik.values.driver_name}
                      onChange={(e: any) => {
                        formik.setFieldValue('driver_name', e.target.value)
                      }}
                    />
                    {formik.touched.driver_name && formik.errors.driver_name && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.driver_name}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xl={4}>
                  <Form.Group className='mb-3'>
                    <Col className='d-flex justify-content-between'>
                      <Form.Label className='required form-label'>Contact Number</Form.Label>
                    </Col>
                    <Form.Control
                      type='text'
                      className='required form-control-solid'
                      placeholder='Contact Number'
                      value={formik.values.mobile_number}
                      onChange={(e: any) => {
                        formik.setFieldValue('mobile_number', e.target.value)
                      }}
                    />
                    {formik.touched.mobile_number && formik.errors.mobile_number && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.mobile_number}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xl={4}>
                  <Form.Group className='mt-3'>
                    <Col className='d-flex justify-content-between'>
                      <Form.Label className='required form-label'>Vehicle Number</Form.Label>
                    </Col>
                    <Form.Control
                      type='text'
                      className='required form-control-solid'
                      placeholder='Vehicle Number'
                      value={formik.values.vehicle_number}
                      onChange={(e: any) => {
                        formik.setFieldValue('vehicle_number', e.target.value)
                      }}
                    />
                    {formik.touched.vehicle_number && formik.errors.vehicle_number && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>
                          {formik.errors.vehicle_number}
                        </span>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row className='mb-6'>
              {formik.values.inquiry_status === 6 as any && (
                <Col xl={4}>
                  <Col className='text-start'>
                    <Form.Label className='form-label text'> Upload Invoice</Form.Label>
                    <div>
                      {/*<input {...getInputProps()} />*/}
                      <input
                        type={'file'}
                        accept='application/pdf, image/jpeg, image/jpg, image/png'
                        id='file-input'
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          let formatType = e?.target?.files?.[0]?.["type"] ?? "" as any
                          let formatArray = ["application/pdf","image/jpeg","image/jpg","image/png"] 
                          if(formatArray.indexOf(formatType) == -1){
                            errorToast("Only PDF, JPEG, JPG and PNG format is Allow")
                            return 
                          }
                          else if (e.target.files) {
                            console.log(e.target.files[0].name, 'e-=-=')
                            uploadInvoiceFile(e.target.files[0])
                            // formik.setFieldValue('invoice_file', e.target.files[0].name)
                          }
                        }}
                      />
                      <label htmlFor='file-input'>
                        <div>
                          <div {...getRootProps({ className: 'dropzone py-12' })}>
                            <div className='dz-message needsclick'>
                              <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                                <KTSVG
                                  path='/media/icons/duotune/files/fil009.svg'
                                  className='svg-icon-muted svg-icon-2hx'
                                />
                              </span>
                              <div className='ms-4'>
                                <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                                  Drag/Drop files here or click here
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </Col>
                  <Form.Label className='form-label text'>
                    {formik?.values?.invoice_file ?
                      <a href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${formik?.values?.invoice_file}`}
                        download
                        target="_blank"
                      >
                        {formik?.values?.invoice_file}
                      </a>
                      : ""}
                    {/* {formik.values.invoice_file} */}
                  </Form.Label>
                </Col>
              )}

              {formik.values.inquiry_status === 6 as any && (
                <Col xl={4}>
                  <Col className='text-start'>
                    <Form.Label className='form-label text'>Upload E-Way Bill</Form.Label>
                    <div>
                      {/*<input {...getInputProps()} />*/}
                      <input
                        type={'file'}
                        accept='application/pdf, image/jpeg, image/jpg, image/png'
                        id='file-input-2'
                        style={{ display: 'none' }}
                        onChange={(e:any) => {
                          let formatType = e?.target?.files?.[0]?.["type"] ?? "" as any
                          let formatArray = ["application/pdf","image/jpeg","image/jpg","image/png"] 
                          if(formatArray.indexOf(formatType) == -1){
                            errorToast("Only PDF, JPEG, JPG and PNG format is Allow")
                            return 
                          }
                          else if (e.target.files) {
                            console.log(e.target.files[0].name, 'e-=-=')
                            uploadEwayBill(e.target.files[0])
                            // formik.setFieldValue('eway_bill_file', e.target.files[0].name)
                          }
                        }}
                      />
                      <label htmlFor='file-input-2'>
                        <div>
                          <div {...getRootProps({ className: 'dropzone py-12' })}>
                            <div className='dz-message needsclick'>
                              <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                                <KTSVG
                                  path='/media/icons/duotune/files/fil009.svg'
                                  className='svg-icon-muted svg-icon-2hx'
                                />
                              </span>
                              <div className='ms-4'>
                                <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                                  Drag/Drop files here or click here
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <Form.Label className='form-label text'>
                      {formik?.values?.eway_bill_file ?
                        <a href={`${process.env.REACT_APP_API_URL_NEW}/downloads/download?folder_name=invoice&image=${formik?.values?.eway_bill_file}`}
                          download
                          target="_blank"
                        >
                          {formik?.values?.eway_bill_file}
                        </a>
                        : ""}
                    </Form.Label>
                  </Col>
                </Col>
              )}
            </Row>
            <Row className='mb-6'>
              <Col className='d-flex justify-content-end'>
                <Button
                  id='kt_sign_in_submit'
                  type='submit'
                  value=''
                  className='btn btn-sm fs-5 btn-primary me-5'
                  disabled={loading}
                >
                  {loading ? "Please Wait..." : id ? 'Edit' : 'Add'}
                </Button>

                <Button
                  type='button'
                  value=''
                  onClick={navigateInuiryListing}
                  className='btn btn-sm btn-light-primary fs-5'
                >
                  Cancel{' '}
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Toaster
          position='top-center'
          toastOptions={{
            duration: 3000,
            // role: 'status',
            // ariaLive: 'polite',
            style: {
              background: 'green',
              color: 'whitesmoke',
            },
            iconTheme: {
              primary: 'red',
              secondary: 'white',
            },
          }}
        />
        <Toaster
          position='top-center'
          toastOptions={{
            duration: 3000,
            // role: 'status',
            // ariaLive: 'polite',
            style: {
              background: 'green',
              color: 'whitesmoke',
            },
            iconTheme: {
              primary: 'red',
              secondary: 'white',
            },
          }}
        />
        {/* sessionMessage */}
      </form >
      {/* <Form>
        <h2 className='mb-5'> Buyer Details </h2>
        <Row className='mb-6'>
          <Col xl={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'>Firm Name</Form.Label>
              <Select
                options={firmList}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select Firm'
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mb-12'>
          <Col xl={4}>
            <Form.Group className=' '>
              <Form.Label className='required fw-bold fs-6 mb-2 form-label'>Buyer Name</Form.Label>
              <Select
                options={buyerList}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select Buyer'
              />
            </Form.Group>
            {errors?.BuyerName && <span className=''>{errors?.BuyerName}</span>}
          </Col>
          <Col xl={4}>
            <Form.Group className='mb-3'>
              <Form.Label className=' required form-label'>Whatsapp Number</Form.Label>
              <Form.Control
                type='text'
                className='form-control-solid'
                placeholder='Whatsapp Number'
              />
            </Form.Group>
          </Col>
          <Col xl={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'> Location </Form.Label>
              <Select
                options={locationList}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select Location'
              />
            </Form.Group>
          </Col>
        </Row>

        <h4 className='mb-5'> Product Details </h4>

        <Row className='mb-10 prdct-dtl-grd'>
          <Col xl={4}>
            <Form.Group className=' '>
              <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                Product Name
              </Form.Label>
              <Select
                options={productList}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select '
              />
            </Form.Group>
          </Col>
          <Col xl={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'> Grade Group </Form.Label>
              <Form.Control type='text' className=' form-control-solid' placeholder='Grade Group' />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'>Inquiry Qty(MT) </Form.Label>
              <Form.Control
                type='text'
                className='form-control-solid'
                placeholder='Inquiry Quantity'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'> Inquiry Rate/Kg </Form.Label>
              <Form.Control type='text' className='form-control-solid' placeholder='Inquiry Rate' />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3'>
              <Form.Label className=' form-label'> Amount </Form.Label>
              <Form.Control type='text' className='form-control-solid' placeholder='Amount' />
            </Form.Group>
          </Col>
          <Col>
            <div className='d-flex'>
              <a
                href='#'
                onClick={() => {
                  setIsShowSourceFild(true)
                }}
                className='btn btn-sm btn-icon mt-8 btn-color-success'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen035.svg'
                  className='svg-icon-muted svg-icon-2hx me-2'
                />
              </a>
              <a
                href='#'
                onClick={() => {
                  setIsShowSourceFild(false)
                  // handleClose()
                }}
                className='btn btn-sm btn-icon mt-8 btn-color-danger'
                style={{visibility: 'hidden'}}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen036.svg'
                  className='svg-icon-muted svg-icon-2hx'
                />
              </a>
            </div>
          </Col>
        </Row>

        {isShowSourceFild && (
          <Row className='mb-12 prdct-dtl-grd'>
            <Col>
              <Form.Group className=' '>
                <Form.Label className='required fw-bold fs-6 mb-2 form-label'>
                  Product Name
                </Form.Label>
                <Select
                  options={productList}
                  className='custom_select'
                  classNamePrefix='Select'
                  placeholder='Select'
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label className='required form-label'> Grade Group </Form.Label>
                <Form.Control
                  type='text'
                  className=' form-control-solid'
                  placeholder='Grade Group'
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label className='required form-label'>Inquiry Qty(MT) </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Inquiry Quantity'
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label className='required form-label'> Inquiry Rate/Kg </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Inquiry Rate'
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label className=' form-label'> Amount </Form.Label>
                <Form.Control type='text' className='form-control-solid' placeholder='Amount' />
              </Form.Group>
            </Col>
            <Col>
              <div className='d-flex'>
                <a
                  href='#'
                  onClick={() => {
                    setIsShowSourceFild(false)
                    // handleClose()
                  }}
                  className='btn btn-sm btn-icon mt-8 btn-color-danger'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen036.svg'
                    className='svg-icon-muted svg-icon-2hx'
                  />
                </a>
                <a
                  href='#'
                  onClick={() => {
                    setIsShowSourceFild(true)
                    // handleClose()
                  }}
                  className='btn btn-sm btn-icon mt-8 btn-color-success'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen035.svg'
                    className='svg-icon-muted svg-icon-2hx'
                  />
                </a>
              </div>
            </Col>
          </Row>
        )}
        <Row className='mb-6'>
          <Col xl={4}>
            <Form.Group className=''>
              <Form.Label className='required form-label'>Inquiry Status </Form.Label>
            </Form.Group>
            <Form.Select
              aria-label='Default select example'
              className='mb-3 form-control-solid border-0'
              name='InquiryStatus'
              onChange={(e) => setState({InquiryStatus: e.target.value})}
            >
              {InquiryStatusList.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xl={4}>
            <Form.Group className='mb-3'>
              <Form.Label className='required form-label'> Assigned </Form.Label>
              <Select
                options={assigneeList}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select Assignee'
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-6'>
          {state.InquiryStatus === '6' && (
            <Col xl={4}>
              <Col className='text-start'>
                <Form.Label className='form-label text'>Upload Invoice</Form.Label>
                <div onClick={open}>
                  <div {...getRootProps({className: 'dropzone py-12'})}>
                    <input {...getInputProps()} />
                    <div className='dz-message needsclick'>
                      <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/files/fil009.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </span>
                      <div className='ms-4'>
                        <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                          Drag/Drop files here or click here
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          )}

          {state.InquiryStatus === '6' && (
            <Col xl={4}>
              <Col className='text-start'>
                <Form.Label className='form-label text'>Upload E Way Bill</Form.Label>
                <div onClick={open}>
                  <div {...getRootProps({className: 'dropzone py-12'})}>
                    <input {...getInputProps()} />
                    <div className='dz-message needsclick'>
                      <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                        <KTSVG
                          path='/media/icons/duotune/files/fil009.svg'
                          className='svg-icon-muted svg-icon-2hx'
                        />
                      </span>
                      <div className='ms-4'>
                        <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                          Drag/Drop files here or click here
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          )}
        </Row>

        {state.InquiryStatus === '5' && (
          <Row>
            <Col xl={4}>
              <Form.Label className='required form-label'> Select Transporter </Form.Label>
              <Select
                options={transportList}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select Transporter'
              />
            </Col>
            <Col xl={4}>
              <Form.Group className='mb-3'>
                <Form.Label className='required form-label'> Driver Name </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-solid'
                  placeholder='Driver Name'
                />
              </Form.Group>
            </Col>
            <Col xl={4}>
              <Form.Group className='mb-3'>
                <Col className='d-flex justify-content-between'>
                  <Form.Label className='required form-label'>Contact Number</Form.Label>
                </Col>
                <Form.Control
                  type='text'
                  className='required form-control-solid'
                  placeholder='Contact Number'
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className='mb-5 mt-5'>
          <Col className='d-flex justify-content-end'>
            <Button
              type='button'
              value=''
              className='btn btn-sm fs-5 btn-primary me-5'
              onClick={() => handleOnSubmit()}
            >
              Add
            </Button>

            <Button
              type='button'
              value=''
              onClick={navigateInuiryListing}
              className='btn btn-sm btn-light-primary fs-5 '
            >
              Cancel{' '}
            </Button>
          </Col>
        </Row>
      </Form> */}
    </>
  )
}

export default AddEditInquiry

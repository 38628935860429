import { useFormik } from 'formik'
import { PageTitle } from '../../../_metronic/layout/core'
import * as Yup from 'yup'
import { Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { checkFinalOfferPriceApi, checkOfferPriceApi, locationListApi, productGradeListApi } from './_request'
import { checkDisplayValue } from '../../utils/utils'
import { KTCardBody } from '../../../_metronic/helpers'
import { OffersListLoading } from '../offer-management/offers-list/components/loading/OffersListLoading'


const OfferWithPrice = () => {
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  const [productGroupList, setProductGroupList] = useState<any>([])
  const [selectedProductGroupList, setSelectedProductGroupList] = useState<any>({})
  const [locationList, setLocationList] = useState<any>([])
  const [selectedLocationList, setSelectedLocationList] = useState<any>({ "label": "Ahmedabad", "value": "3cb71b1b-1b44-40d2-8dd7-c85d7b994549" })
  const [offerDetail, setOfferDetail] = useState<any>([])
  const [offerPrice, setOfferPrice] = useState<any>([])

  const offerValidation = Yup.object().shape({
    product_group_id: Yup.string()
      .required('Product Group is required'),
    destination_location_id: Yup.string()
      .required('Location is required'),
    quantity: Yup.string()
      .required('Quantity is required'),
  })

  const formik = useFormik({
    initialValues: {
      product_group_id: '',
      destination_location_id: '3cb71b1b-1b44-40d2-8dd7-c85d7b994549',
      quantity: '25',
    },
    validationSchema: offerValidation,
    onSubmit: async (values: any) => {
      await checkOfferPriceApi(values.product_group_id, values.destination_location_id).then(async (data: any) => {
        if (data?.responseStatus == 200 && data?.responseData?.list) {
          let offerData1 = data?.responseData?.list ?? []
          setOfferDetail(offerData1)
          let offerReqData = offerData1.map((x: any) => {
            let i = {} as any
            i.offer_id = x.id
            i.qty = formik.values.quantity
            i.destination_location_id = formik.values.destination_location_id
            return i
          })
          let data2 = await checkFinalOfferPriceApi(offerReqData) as any
          if (data2.responseStatus == 200 && data2.responseData) {
            setOfferPrice(data2.responseData)
          }
          else {
            setOfferPrice([])
            setOfferDetail([])
            let message = data2?.responseMessage ? data2?.responseMessage : 'Something went wrong'
            setSessionMessage({ type: 'error', message: message, show: true })
          }
        } else {
          setOfferPrice([])
          setOfferDetail([])
          let message = data?.responseMessage ? data?.responseMessage : 'Something went wrong'
          setSessionMessage({ type: 'error', message: message, show: true })
        }
      }).catch((err: any) => {
        setOfferPrice([])
        setOfferDetail([])
        let message = err?.message
          ? err.message
          : err?.responseMessage
            ? err.responseMessage
            : ('Something went wrong' as any)
        setSessionMessage({ type: 'error', message: message, show: true })
      })
    },
  });

  useEffect(() => {
    if (sessionMessage.show) {
      setTimeout(() => {
        setSessionMessage({
          type: '',
          message: '',
          show: false,
        })
      }, 5000)
    }
  }, [sessionMessage.message])

  const getProductGroupList = async (search: any) => {
    try {
      let { responseStatus, responseMessage, data } = await productGradeListApi(search) as any
      if (responseStatus == 200 && data) {
        setProductGroupList(
          data.map((x: any) => {
            x.label = x.name
            x.value = x.id
            return x
          })
        )
      } else {
        let message = responseMessage ? responseMessage : 'Something went wrong'
        setSessionMessage({ type: 'error', message: message, show: true })
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
          ? err.responseMessage
          : ('Something went wrong' as any)
      setSessionMessage({ type: 'error', message: message, show: true })
    }
  }

  const getLocationList = async (search: any) => {
    try {
      let { responseStatus, responseData, responseMessage } = await locationListApi(search) as any
      if (responseStatus == 200 && responseData) {
        setLocationList(
          responseData.map((x: any) => {
            x.label = x.name
            x.value = x.id
            return x
          })
        )
      } else {
        let message = responseMessage ? responseMessage : 'Something went wrong'
        setSessionMessage({ type: 'error', message: message, show: true })
      }
    } catch (err: any) {
      let message = err?.message
        ? err.message
        : err?.responseMessage
          ? err.responseMessage
          : ('Something went wrong' as any)
      setSessionMessage({ type: 'error', message: message, show: true })
    }
  }

  const getSelectedProductGroupList = () => {
    if (selectedProductGroupList.value == formik.values.product_group_id) {
      if (!productGroupList.find((x: any) => x.value == formik.values.product_group_id)) {
        productGroupList.push(selectedProductGroupList)
        setProductGroupList(productGroupList)
      }
      return [selectedProductGroupList]
    } else return []
  }

  const getSelectedLocationList = () => {
    if (selectedLocationList.value == formik.values.destination_location_id) {
      if (!locationList.find((x: any) => x.value == formik.values.destination_location_id)) {
        locationList.push(selectedLocationList)
        setLocationList(locationList)
      }
      return [selectedLocationList]
    } else return []
  }

  useEffect(() => {
    getProductGroupList('')
    getLocationList('')
  }, [])

  return (
    <>
      <PageTitle>Offer With Price</PageTitle>

      <div className='card card-body p-12 mt-10'>
        <Row className='mb-5'>
          <Col md={1}>
          </Col>
          <Col md={10}>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              <Row className='mb-5'>
                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold required form-label'>Product Group</Form.Label>
                    <Select
                      options={productGroupList}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Select Product Group'
                      name='product_group_id'
                      onInputChange={(e: any) => {
                        getProductGroupList(e)
                      }}
                      value={getSelectedProductGroupList()}
                      onChange={(e: any) => {
                        formik.setFieldValue('product_group_id', e.value)
                        setSelectedProductGroupList(e)
                      }}
                    />
                    {formik?.touched?.product_group_id && formik?.errors?.product_group_id && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>{formik?.errors?.product_group_id as any}</span>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold required form-label'>Location</Form.Label>
                    <Select
                      options={locationList}
                      className='custom_select'
                      classNamePrefix='Select'
                      placeholder='Select Location'
                      onInputChange={(e: any) => {
                        getLocationList(e)
                      }}
                      value={getSelectedLocationList()}
                      onChange={(e: any) => {
                        formik.setFieldValue('destination_location_id', e.value)
                        setSelectedLocationList(e)
                      }}
                    />
                    {formik?.touched?.destination_location_id && formik?.errors?.destination_location_id && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>{formik?.errors?.destination_location_id as any}</span>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xl={4}>
                  <Form.Group>
                    <Form.Label className='fw-bold required form-label'>Quantity</Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter Quantity'
                      value={formik.values.quantity}
                      onChange={(e: any) => {
                        formik.setFieldValue('quantity', e.target.value.trimStart())
                      }}
                    />
                    {formik.touched.quantity && formik.errors.quantity && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert' className={'text-danger'}>{formik?.errors?.quantity as any}</span>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                </Col>
                <Col md={4}>
                  <div className='d-grid mb-10'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? "Please Wait..." : "Price"}
                    </button>
                  </div>
                </Col>
                <Col md={4}>
                </Col>
              </Row>

              <ToastContainer className='p-3' position={'top-end'}>
                <Toast
                  show={sessionMessage.show}
                  onClose={() => {
                    setSessionMessage({
                      type: '',
                      message: '',
                      show: false,
                    })
                  }}
                  bg='danger'
                >
                  <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
                </Toast>
              </ToastContainer>
            </form>
            <KTCardBody className='py-4'>
              <div className='table-responsive'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer offer-with-price-table'
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light'
                    >
                      <th style={{width:"20%"}}>
                        Product Variation
                      </th>
                      <th style={{width:"20%"}}>
                        MFI
                      </th>
                      <th style={{width:"20%"}}>
                        Price
                      </th>
                      <th style={{width:"150px"}}>
                        Offer ID
                      </th>
                    </tr>
                  </thead>
                  <tbody className='text-gray-600 fw-bold'>
                    
                    {offerPrice && offerPrice.length > 0 ? (
                        offerPrice.map((row: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td>{checkDisplayValue(offerDetail.find((x:any)=>x.id == row?.offer_id)?.product_variation)}</td>
                              <td>{checkDisplayValue(offerDetail.find((x:any)=>x.id == row?.offer_id)?.mfi_kvalue)}</td>
                              <td>{checkDisplayValue(row?.totalPrice)}</td>
                              <td>{checkDisplayValue(offerDetail.find((x:any)=>x.id == row?.offer_id)?.offer_number)}</td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                              No Offers Found
                            </div>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              {formik.isSubmitting && <OffersListLoading />}
            </KTCardBody>
          </Col>
          <Col md={1}>
          </Col>
        </Row>

      </div>
    </>
  )
}
export default OfferWithPrice

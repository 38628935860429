const publicIp = require('public-ip')
let ipv4 = "";

export function getPublicIP() {
  return new Promise(async (resolve) => {
    if (ipv4 !== '') {
      resolve(ipv4)
      return
    }
    ipv4 = await publicIp.v4()
    resolve(ipv4)
  })
}

export function checkDisplayValue(value) {
  if(value === 0){
    return value
  }
  else if(!value || value == ""){
    return "-"
  }
  else{
    return value
  }
}

export function checkEmptyValue(value) {
  if(value === 0){
    return true
  }
  else if(!value || value == ""){
    return false
  }
  else{
    return true
  }
}

// export function formatAddress({...value}) {
//   let str = ""
//   for(let i in value){
//     if(i == 0){
//       if(checkEmptyValue(value)){
//         str 
//       }
//     }else{

//     }
//   }
// }

export const capitalizeFirstLetter = (string) => {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
};

// {shipingAD2
//   ? `${shipingAD2.address_line_1},${shipingAD2.address_line_2}${
//       shipingAD2.district ? shipingAD2.district : '-'
//     },${shipingAD2.city ? shipingAD2.city : '-'},${
//       shipingAD2.state ? shipingAD2.state : '-'
//     },${shipingAD2.country ? shipingAD2.country : '-'},${
//       shipingAD2.pincode ? shipingAD2.pincode : '-'
//     }`
//   : '-'}  

export const getFormatedAddress = (data) => {
  let address = "";
  if (checkEmptyValue(data?.address_line_1)) {
    address += capitalizeFirstLetter(data?.address_line_1);
  }
  if (checkEmptyValue(data?.address_line_2)) {
    if(checkEmptyValue(data?.address_line_1)){
      address += `, ${capitalizeFirstLetter(data?.address_line_2)}`;
    }
    else{
      address += capitalizeFirstLetter(data?.address_line_2);
    }
  }
  if (checkEmptyValue(data?.district)) {
    if(checkEmptyValue(data?.address_line_2)){
      address += `, ${capitalizeFirstLetter(data?.district)}`;
    }
    else{
      address += capitalizeFirstLetter(data?.district);
    }
  }
  if (checkEmptyValue(data?.city)) {
    if(checkEmptyValue(data?.district)){
      address += `, ${capitalizeFirstLetter(data?.city)}`;
    }
    else{
      address += capitalizeFirstLetter(data?.city);
    }
  }
  if (checkEmptyValue(data?.state)) {
    if(checkEmptyValue(data?.city)){
      address += `, ${capitalizeFirstLetter(data?.state)}`;
    }
    else{
      address += capitalizeFirstLetter(data?.state);
    }
  }
  if (checkEmptyValue(data?.country)) {
    if(checkEmptyValue(data?.state)){
      address += `, ${capitalizeFirstLetter(data?.country)}`;
    }
    else{
      address += capitalizeFirstLetter(data?.country);
    }
  }
  if (checkEmptyValue(data?.pincode)) {
    if(checkEmptyValue(address)){
      address += `, ${data?.pincode.toString()}`;
    }
    else{
      address += ` ${data?.pincode.toString()}`;
    }
  }
  return checkDisplayValue(address);
};
export const getTotalFormatPrice = (value) => {
  return value ? value.toLocaleString('en-IN') : 0
};

export const getFormatPrice = (value) => {
  let indiaFormat = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    currencyDisplay: 'code'
  }); 
  if(typeof value == 'number'){
    let n = parseFloat(value).toFixed(2)
    return indiaFormat.format(n).replace("INR", '');
  }
  else if(!isNaN(value)){
    let n = parseFloat(value).toFixed(2)
    return indiaFormat.format(n).replace("INR", '');
  }
  return value  
}

let validFractions = [
  '0.025', '0.050', '0.075', '0.100', '0.125', '0.150', '0.175', '0.200', '0.225',
  '0.250', '0.275', '0.300', '0.325', '0.350', '0.375', '0.400', '0.425', '0.450',
  '0.475', '0.500', '0.525', '0.550', '0.575', '0.600', '0.625', '0.650', '0.675',
  '0.700', '0.725', '0.750', '0.775', '0.800', '0.825', '0.850', '0.875', '0.900',
  '0.925', '0.950', '0.975'
];

export const checkDecimalNumberValidation = (num) => {
  if(num % 1 == 0){
    return true
  }
  else{
    let fractionalPart = (num % 1).toFixed(3);
    return validFractions.find((x)=>x==fractionalPart) ? true : false
  }
}

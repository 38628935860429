/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, loginNew} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useNavigate} from 'react-router-dom'
// import {useAlert} from 'react-alert'
import Toast from 'react-bootstrap/Toast'
import {ToastContainer} from 'react-bootstrap'
import Setup2FA from './LoginSetup2fa'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

  const [loading, setLoading] = useState(false)
  const [show2FA, setShow2FA] = useState(false)
  const [authToken, setAuthToken] = useState<any>("")
  const [objUser, setObjUser] = useState<any>({})
  
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()

  useEffect(()=>{
    if(sessionMessage.show){
      setTimeout(()=>{
        setSessionMessage({type: '', message: '', show: false})
      },4500)
    }
  },[sessionMessage.message])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const dataNew: any = await loginNew(values.email, values.password).then((res:any)=>{
          if (res?.data?.responseStatus == 200) {
            if(res?.data?.responseData?.user?.is_2fa_enable == 1){
              navigate(
                '/auth/verifyotp',
                {
                  state: {
                    token: res?.data?.responseData?.token ?? "",
                    user: JSON.stringify(res?.data?.responseData?.user) ?? ""
                  }
                }
              )
            }
            else if(res?.data?.responseData?.user?.role_id == 1){
              setShow2FA(true)
              setLoading(false)
              setAuthToken(res?.data?.responseData?.token ?? "")
              setObjUser(res?.data?.responseData?.user ?? {})
            }
            else{
              localStorage.setItem('authtoken', res?.data?.responseData?.token)
              localStorage.setItem('objUser', JSON.stringify(res?.data?.responseData?.user))
              window.location.replace('/offer-management/offers')
            }
          }else{
            setSessionMessage({type: 'danger', message: res?.data?.responseMessage ?? "SomeThing Went Wrong", show: true})
            setLoading(false)
          }
        }).catch((err:any)=>{
          if(err?.response?.status == 429){
            setSessionMessage({type: 'danger', message: err?.response?.statusText ?? "SomeThing Went Wrong", show: true})
          }
          else{
            setSessionMessage({type: 'danger', message: err?.message ?? "SomeThing Went Wrong", show: true})
          }
          setSubmitting(false)
          setLoading(false)
        })
        
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
      // setLoading(true)
      // try {
      //   const {data: auth} = await login(values.email, values.password)
      //   saveAuth(auth)
      //   const {data: user} = await getUserByToken(auth.api_token)
      //   setCurrentUser(user)
      // } catch (error) {
      //   console.error(error)
      //   saveAuth(undefined)
      //   setStatus('The login details are incorrect')
      //   setSubmitting(false)
      //   setLoading(false)
      // }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Login</h1>
        <h2>Test React Pipeline at 01:23PM 24/JAN/24: Update line 140</h2>
        <div className='text-gray-500 fw-semibold fs-6'>Welcome to Polymart</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      {/* <div className='row g-3 mb-9'> */}
      {/* begin::Col */}
      {/* <div className='col-md-6'> */}
      {/* begin::Google link */}
      {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}
      {/* end::Google link */}
      {/* </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-md-6'> */}
      {/* begin::Google link */}
      {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a> */}
      {/* end::Google link */}
      {/* </div> */}
      {/* end::Col */}
      {/* </div> */}
      {/* end::Login options */}

      {/* begin::Separator */}
      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
      {/* end::Separator */}

      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={loading || formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <Setup2FA show2FA={show2FA} setShow2FA={setShow2FA} token={authToken} userObj={objUser}/>
      <ToastContainer className='p-3' position={'top-end'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg='danger'
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </form>
  )
}
